<template>
    <div class="jh-container" style="width: 1200px;">
        <div class="jh-ui-header">
            <h1>SMS 발송</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-cols">
            <!--템플릿목록-->
            <div class="jh-form-wrap is-pt-10 is-col-fix" style="width: 55%;" v-show="template">
                <div class="jh-ui-header">
                    <h2>SMS 템플릿</h2>
                    <div class="is-right">
                        <v-text-field clearable class="jh-form" style="width: 250px;" placeholder="제목검색" v-model="searchValue_dataTable1.TITLE" @keypress.enter="search_dataTable1">          
                            <template v-slot:append>
                                <v-btn class="jh-btn is-icon" @click="search_dataTable1"><i class="jh-icon-search-sm is-blue"></i></v-btn>
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <data-tables
                    ref="dataTable1"
                    :data-table-options="dataTable1Options"
                    @click:row="onClickRow_dataTable1"
                    :paginationOptions="paginationOptions_dataTable1"
                    v-model="selectedRows_dataTable1"
                />
            </div>
            <!--템플릿목록-->
            <div>
                <div class="jh-form-wrap is-pt-10">
                    <div class="jh-ui-header">
                        <div class="is-left">
                            <!-- <v-btn class="jh-btn is-icon" :title="[template ? '확대' : '축소']" @click="template=!template"><i :class="[template ? 'jh-icon-transfer-left' : 'jh-icon-transfer-right']"></i></v-btn> -->
                            <h2 class="is-ml-5">발송고객 리스트</h2>
                        </div>
                        <div class="is-right">
                        </div>
                    </div>
                    <data-tables
                        ref="dataTable2"
                        :data-table-options="dataTable2Options"
                        @click:row="onClickRow_dataTable2"
                        v-model="selectedRows_dataTable2"
                    />
                    <div class="jh-ui-header">
                        <h3>고객정보</h3>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="reset">초기화</v-btn>
                            <v-btn class="jh-btn is-border-blue" :disabled="checkDisableAddRowBtn()" @click="addRow">추가</v-btn>
                            <v-btn class="jh-btn is-del" :disabled="!selectedKey_dataTable2" @click="this.delete">삭제</v-btn>
                        </div>
                    </div>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="80px">
                            <col>
                            <col width="80px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label>고객번호</label></th>
                                <td>
                                    <v-text-field
                                        :disabled="CHK_BL == true"
                                        class="jh-form"
                                        clearable
                                        maxlength="16"
                                        v-model="customerInfo.CUST_NO"
                                        oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '').replace(/(\..*)\./g, '$1');"
                                        @input="onInputCustNo"
                                        @keypress.enter="getCustomerInfo"
                                    >          
                                        <template v-slot:append>
                                            <v-btn class="jh-btn is-icon" @click="getCustomerInfo"><i class="jh-icon-search-sm is-blue"></i></v-btn>
                                        </template>
                                    </v-text-field>
                                </td>
                                <th colspan="2">
                                    <v-checkbox class="jh-check is-mr-15" v-model="CHK_BL"  @click="noCertMember" label="미인증"></v-checkbox>
                                </th>
                            </tr>
                            <tr>
                                <th><label>고객명</label></th>
                                <td>
                                    <v-text-field class="jh-form" :disabled="CHK_BL == true" clearable maxlength="20" v-model="customerInfo.CUST_NM" />
                                </td>
                                <th><label>휴대전화</label></th>
                                <td>
                                    <v-text-field
                                        class="jh-form"
                                        clearable
                                        maxlength="13"
                                        placeholder="000-0000-0000"
                                        v-model="CUST_PHN_NO"
                                        @keyup="keyUpTelInp(CUST_PHN_NO)"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="jh-tbl-detail is-mt-5">
                        <colgroup>
                            <col width="80px">
                            <col>
                            <col width="80px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th class="is-bg-light-blue"><label>보내는 사람</label></th>
                                <td colspan="1">
                                    <v-select class="jh-form" :items="senderList" placeholder="선택" v-model="selectedSender" @change="changeNum(selectedSender)"></v-select>
                                </td>
                                <td colspan="2" class="is-jh-5">
                                    <em>{{nowNum}}</em>
                                </td>                                
                                <!-- <td colspan="3">
                                    <span class="is-bold is-ml-5 is-mr-5">고객만족센터</span>
                                    <em>1588-2428</em>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="jh-card is-border-blue is-mt-10">
                    <div class="jh-card-header">
                        <h1>발송내용</h1>
                        <div class="is-right">                                    
                            <v-btn class="jh-btn" @click="openE030102P01"><i class="jh-icon-popup"></i><span>발송이력</span></v-btn>
                        </div>
                    </div>
                    <div class="jh-card-body">
                        <table class="jh-tbl-detail">
                            <colgroup>
                                <col width="80px">
                                <col>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><label>제목</label></th>
                                    <td>
                                        <v-text-field class="jh-form" v-model="smsTemplateDetail.TITLE" />
                                        <!-- {{ titleLen }} / 150 -->
                                    </td>
                                </tr>
                                <tr>
                                    <th><label>내용</label></th>
                                    <td>
                                        <v-textarea class="jh-form" style="height:270px" v-model="smsTemplateDetail.CONTENT" />
                                        <div class="jh-txt-counter is-txt-right"><strong :class="contentLen > 4000 ? 'is-txt-red': ''">{{contentLen | inputNumberFormat}}</strong> / 4,000 byte</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="jh-card-footer">
                        <v-btn class="jh-btn is-md is-main" style="width: 100px;" v-if="mixin_set_btn($options.name, 'btnSend')" :loading="isLoading" @click="send">발송</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

import iconv from "iconv-lite";
import he from "he";

export default {
    name: "MENU_E030101", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables
    },
    props: {},
    data() {
        return {
            nowNum: "",
            CHK_BL: false,
            template: true,
            
            isLoading: false,
            
            senderList: [],

            searchValue_dataTable1: {
                CHNL_KIND: "SMS",
                TITLE: null,
                USE_YN: "Y"
            },

            inquiredCustomerNO: null,
            selectedSender: "",

            dataTable1Options: {
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "ROWNUM", align: "center", width: "50px" },
                    { text: "제목", value: "TITLE" },
                ],
                height: "690px",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList_dataTable1,
            },
            paginationOptions_dataTable1: {
                totalVisible: 10
            },

            dataTable2Options: {
                disablePagination: true,
                fixedHeader: true,
                headers: [
                    { text: "순번", value: "index", align: "center", width: "50px" },
                    { text: "고객번호", value: "CUST_NO", align: "center", width: "100px" },
                    { text: "고객명", value: "CUST_NM",  align: "center", width: "100px" },
                    { text: "전화번호", value: "CUST_CELLPHN_NO", align: "center" },
                ],
                height: "116px",
                hideDefaultFooter: true,
                itemKey: "index",
                items: [],
                itemsPerPage: 50,
                noDataText: "발송고객이 없습니다.",
                page: 1,
                singleSelect: true
            },

            requestData: {
                headers: {
                    SERVICE: "message.template",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },
            requestData_dataTable1: {},
            requestData_dataTable2: {},

            selectedKey_dataTable1: null,
            selectedRows_dataTable1: [],
            selectedKey_dataTable2: null,
            selectedRows_dataTable2: [],

            customerInfo: {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            },
            CUST_PHN_NO: "",

            smsTemplateDetail: {
                ID: null,
                TITLE: "",
                CONTENT: ""
            },
        };
    },
    filters: {
        inputNumberFormat(v){
            return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {        
        dataTable1Instance: function() {
            return this.$refs.dataTable1;
        },
        dataTable2Instance: function() {
            return this.$refs.dataTable2;
        },
        titleLen: function() {
            return iconv.encode(he.decode(this.smsTemplateDetail.TITLE), "euc-kr").length;
        },
        contentLen: function() {
            let pleng =  iconv.encode(he.decode(this.smsTemplateDetail.CONTENT), "euc-kr").length;

            let frmLeng = pleng.toString();
            frmLeng = frmLeng.replace(/(\d{3})(\d{3})(\d{3})/, '$1,$2,$3');

            return frmLeng;
        }
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams_dataTable1();

            let commonCodeItems = await this.mixin_common_code_get_all([ "TWB100" ]);

            this.senderList = this.mixin_common_code_get(commonCodeItems, "TWB100");

            // this.selectedSender = '01'
            this.selectedSender = this.senderList[0].value
            this.nowNum = _.find(this.senderList, { 'value': this.selectedSender }).info4
            console.log(" this.nowNum init  ",this.nowNum)
            console.log(" this.senderList init  ",this.senderList)

        },

        setDataTableParams_dataTable1: function() {
            this.requestData_dataTable1 = _.cloneDeep(this.requestData);
            // header 세팅
            this.requestData_dataTable1.headers["URL"] = "/api/message/template/list";
            this.requestData_dataTable1.headers["METHOD"] = "list";
            this.requestData_dataTable1.headers["ASYNC"] = false;

            // sendData 세팅
            this.requestData_dataTable1.sendData = this.searchValue_dataTable1;
            // this.requestData_dataTable1.sendData["USER_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        changeNum(selectedSender){
            console.log(" this.senderList  ",this.senderList)
            console.log(" this.selectedSender  ",this.selectedSender)
            console.log(" this.selectedSender.value  ",this.selectedSender.value)

            this.nowNum = _.find(this.senderList, { 'value': this.selectedSender }).info4
            console.log(" this.nowNum  ",this.nowNum)
        },
        getList_dataTable1: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData_dataTable1.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            this.requestData_dataTable1.headers["PAGES_CNT"] = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData_dataTable1.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
            else this.requestData_dataTable1.sendData["SORT_ORDR"] = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData_dataTable1.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData_dataTable1.sendData["SORT_DRCT"] = undefined;

            return this.common_postCall(this.requestData_dataTable1).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert("시스템에 오류가 발생하였습니다.", "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
                    item.CONTENT = _.replace(item.CONTENT, /\r\n/gi, "\n");
                    item.CONTENT = this.restoreXSS(item.CONTENT);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search_dataTable1: function() {
            this.setDataTableParams_dataTable1();

            if (this.dataTable1Options.page > 1) this.dataTable1Options.page = 1;
            else this.dataTable1Instance.loadData();
        },
        checkDisableAddRowBtn: function() {
            if ((!_.isEmpty(this.customerInfo.CUST_NO) && this.customerInfo.CUST_NO != "00000000" && !this.customerInfo.IsInquire) || this.checkExistCust()){
                return true;
            }
            return false;
        },
        checkExistCust: function() {
            let instance = this.$refs.dataTable2;
            let data = instance ? instance.getData() : null;
            if (data && this.customerInfo && _.find(data, { CUST_NO: (this.customerInfo.CUST_NO || "99999999"), CUST_NM: this.customerInfo.CUST_NM, CUST_CELLPHN_NO: this.customerInfo.CUST_CELLPHN_NO })){
                return true;
            }
            return false;
        },
        onInputCustNo: function(e) {
            if (this.inquiredCustomerNO != e) {
                this.customerInfo.CUST_NM = null;
                this.customerInfo.CUST_CELLPHN_NO = null;
                this.customerInfo.IsInquire = false;
            }
        },
        getCustomerInfo: function(addRow) {
            if (!this.customerInfo.CUST_NO || this.customerInfo.CUST_NO.length < 8) {
                this.common_alert("고객번호는 8자리 이상 입력해야 합니다.", "noti");
                return false;
            }

            let requestData = {
                headers: {
                    SERVICE: "message.channel",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };

            // header 세팅
            requestData.headers["URL"] = "/api/message/channel/customerinfo/inquire";
            requestData.headers["METHOD"] = "inquire";
            requestData.headers["ASYNC"] = false;

            // sendData 세팅
            requestData.sendData.SRCH_KEY = this.customerInfo.CUST_NO;
            requestData.sendData.QryTpCode = "0"; // 고객번호로 조회 시 "0"

            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                // this.customerInfo.CUST_NO = data[0].ClntNo;
                this.customerInfo.CUST_NM = data[0].ClntNm;
                this.customerInfo.CUST_CELLPHN_NO = (data[0].ClntHpCode ? data[0].ClntHpCode + "-" : "") + (data[0].ClntHpExno ? data[0].ClntHpExno + "-" : "") + data[0].ClntHpSeqno;
                this.CUST_PHN_NO = (data[0].ClntHpCode ? data[0].ClntHpCode + "-" : "") + (data[0].ClntHpExno ? data[0].ClntHpExno + "-" : "") + data[0].ClntHpSeqno;
                this.customerInfo.IsInquire = true;
                this.inquiredCustomerNO = data[0].ClntNo;

                if (addRow === true) this.addRow();
            });
        },
        reset: function() {
            // 선택된 열 초기화
            this.selectedKey_dataTable2 = null;
            this.selectedRows_dataTable2 = [];
            // 상세 정보 초기화
            this.customerInfo = {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            };
        },
        addRow: function() {
            let totalCount = this.dataTable2Instance.getTotalCount(); // 현재 Grid의 전체 데이터 수 가져오기
            if (totalCount >= 10) {
                this.common_alert("발송고객은 최대 10명까지만 추가 가능합니다.", "noti");
            }
            else if (!_.isEmpty(this.customerInfo.CUST_NO) && this.customerInfo.CUST_NO.length != 8 && this.CHK_BL == false) {
                this.common_alert("고객번호는 8자리로 입력해야 합니다.", "noti");
            }
            else if (!this.customerInfo.CUST_NM && this.CHK_BL == false) {
                this.common_alert("고객명을 입력해야 합니다.", "noti");
            }
            else if (this.customerInfo.CUST_CELLPHN_NO == null) {
                this.common_alert("휴대전화번호를 입력해야 합니다.", "noti");
            }
            else if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.customerInfo.CUST_CELLPHN_NO)) {
                this.common_alert("잘못된 휴대전화번호입니다.", "noti");
            }
            else {
                let row = _.cloneDeep(this.customerInfo);
                if (_.isEmpty(row.CUST_NO)){
                    row.CUST_NO = "00000000";
                    row.CUST_NM = "미인증고객";
                } 
                row.index = totalCount + 1;
                this.dataTable2Instance.addRow(row); // row 추가
                this.selectedKey_dataTable2 = row.index;
                if (this.selectedRows_dataTable2.length > 0) this.selectedRows_dataTable2.splice(0, 1, row);
                else this.selectedRows_dataTable2.push(row);
            }
        },
        delete: function() {
            this.common_confirm('삭제하시겠습니까?', this.deleteRow, null, null, null, 'chk');
        },
        deleteRow: function() {
            let index = _.findIndex(this.dataTable2Instance.getData(), { CUST_NO: (this.customerInfo.CUST_NO || "99999999"), CUST_NM: this.customerInfo.CUST_NM, CUST_CELLPHN_NO: this.customerInfo.CUST_CELLPHN_NO });
            if (index >= 0) {
                this.dataTable2Instance.deleteRow(index); // row 삭제
                this.$nextTick(() => {
                    let items = this.dataTable2Instance.getData();
                    for (let i = index; i < items.length; i++) {
                        this.dataTable2Instance.updateRow(i, "index", i + 1); // row 업데이트
                        // items[i].index = i + 1;
                    }
                });
            }
            
            this.selectedKey_dataTable2 = null;
            this.selectedRows_dataTable2 = [];
            this.customerInfo = {
                CUST_NO: null,
                CUST_NM: null,
                CUST_CELLPHN_NO: null,
                IsInquire: false
            };
        },
        send: function() {
            let totalCount = this.dataTable2Instance.getTotalCount();
            // if (totalCount == 0 && this.CHK_BL == false) {
            if (totalCount == 0) {
                this.common_alert("발송 대상이 존재하지 않습니다.", "noti");
            }
            else if (!this.smsTemplateDetail.TITLE) {
                this.common_alert("제목을 입력해야 합니다.", "noti");
            }
            else if (this.titleLen > 150) {
                this.common_alert(`제목은 150Byte를 초과할 수 없습니다.\n현재 ${this.titleLen}Byte 입력하셨습니다.`, "noti");
            }
            else if (!this.smsTemplateDetail.CONTENT) {
                this.common_alert("내용을 입력해야 합니다.", "noti");
            }
            else if (this.contentLen > 4000) {
                this.common_alert(`내용은 4,000Byte를 초과할 수 없습니다.\n현재 ${this.contentLen}Byte 입력하셨습니다.`, "noti");
            }
            // else if (!this.selectedSender) {
            //     this.common_alert("보내는 사람을 선택해야 합니다.", "noti");
            // }
            else {
                this.common_confirm('발송하시겠습니까?', this.sendSMS, null, null, null, 'chk');
            }
        },
        sendSMS: function() {
            console.log("CUST_CELLPHN_NO-->",this.customerInfo.CUST_CELLPHN_NO);
            this.isLoading = true;
            console.log("this.selectedSender sms ",this.selectedSender)
            console.log("this.senderList sms ",this.senderList)
            let rtnPhnNo = _.find(this.senderList, { 'value': this.selectedSender }).info4
            // let rtnPhnNo = _.find(this.senderList, { value: this.selectedSender }).info4;
            if (!rtnPhnNo) {
                this.common_alert("회신번호가 존재하지 않습니다.\n공통코드에 등록 후 다시 시도해 주세요.", "error");
                this.isLoading = false;
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: "message.sms",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            };
            
            // header 세팅
            requestData.headers["URL"] = "/api/message/sms/send";
            requestData.headers["METHOD"] = "send";
            requestData.headers["ASYNC"] = false;

            // sendData 세팅
            if(this.CHK_BL == true){
                requestData.sendData.CUST_CELLPHN_NO = this.customerInfo.CUST_CELLPHN_NO;
            }
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData.USER_NM = this.$store.getters["userStore/GE_USER_ROLE"].userName;
            requestData.sendData.USER_ATTR_A = this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A;
            requestData.sendData.ATTR_DIV_NM_A = this.$store.getters["userStore/GE_USER_ROLE"].ATTR_DIV_NM_A;
            requestData.sendData.RTN_PHN_NO = rtnPhnNo; // 회신번호
            requestData.sendData.TITLE = this.smsTemplateDetail.TITLE;
            requestData.sendData.CONTENT = this.smsTemplateDetail.CONTENT;
            requestData.sendData.RECP_LIST = _.map(this.dataTable2Instance.getData(), item => {
                let newItem = {};
                _.each(Object.keys(item), key => {
                    if (key != "IsInquire") newItem[key] = item[key];
                });

                return newItem;
            }) ;
            
            this.common_postCall(requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
                    let errorMsg = _.find(errorMessages, msg => {
                        return _.includes(msg, "에러 메시지: ");
                    });
                    let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
                    this.common_alert(msg, "error");
                    // this.isLoading = false;
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                
                this.common_alert("정상 처리되었습니다.", "done");
                // this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        keyUpTelInp(val) {
            let numPttn = /[^0-9]/g;
            val = val.replace(numPttn, "");
            if(val.length > 8)  {
                val = this.mixin_newPhoneFormat(val);
            }
            this.customerInfo.CUST_CELLPHN_NO = val;
            this.CUST_PHN_NO = val;
        },
        onClickRow_dataTable1: function(item, row) { 
            if (this.selectedKey_dataTable1 === item.ID) {
                row.select(false);
                this.selectedKey_dataTable1 = null;
                this.smsTemplateDetail = {
                    ID: null,
                    TITLE: "",
                    CONTENT: ""
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable1 = item.ID;
                this.smsTemplateDetail = Object.assign(this.smsTemplateDetail, item);
            }
        },
        onClickRow_dataTable2: function(item, row) { 
            if (this.selectedKey_dataTable2 === item.index) {
                row.select(false);
                this.selectedKey_dataTable2 = null;
                this.customerInfo = {
                    CUST_NO: null,
                    CUST_NM: null,
                    CUST_CELLPHN_NO: null,
                    IsInquire: false
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable2 = item.index;
                this.customerInfo = Object.assign(this.customerInfo, item);
            }
        },

        setCustomer: function(cust_no) {
            this.customerInfo.CUST_NO = cust_no;
            this.getCustomerInfo(true);
        },
        noCertMember(){
            if(this.CHK_BL == true){
                this.customerInfo.CUST_NO = "00000000"
                this.customerInfo.CUST_NM = "미인증고객"
            }else{
                this.customerInfo.CUST_NO = ""
                this.customerInfo.CUST_NM = ""
            }
            
        },

        openE030102P01() {
            this.$openWindow('E030102P01','E030102P01','1300','895');
        }
    },
    created() {},
    mounted() {
        this.init();
    },
    watch: {}
};
</script>

<style></style>